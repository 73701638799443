.jumbotron {
  background: url('../images/brutta_storia_header.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 30vw;
  h1 {
    background: rgba(#fff, 0.6);
    text-align: center;
    padding: $grid-gutter-width 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

#sidebar {
  aside {
    margin: ($grid-gutter-width * 2) 0;
    &:first-of-type {
      margin: 0 0 ($grid-gutter-width * 2) 0;
      h3 {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}

#dizionario {
  header {
    a {
      padding: 0 0.2em 0 0;
    }
  }
}

#singolo-lemma__detail {
  article {
    header {
      font-style: italic;
      text-align: right;
      margin-bottom: $grid-gutter-width;
    }
    blockquote {
      margin: $grid-gutter-width 0 0 0;
    }
    footer {
      margin-top: $grid-gutter-width;
    }
  }
}

.functional_footer_menu {
    margin-top: ($grid-gutter-width * 2);
}

.cc-cookies {
  width: 100%!important;
}